#top {
  #contents {
    width: 100%;
    margin-top: -120px;
    //メインスライダー
    .mv_slider {
      width: 100%;
      margin-bottom: 122px;
      .slider_blk {
        width: 100%;
        position: relative;
        opacity: 0;
        &.slick-initialized{
          opacity: 1
        }
        .slick-arrow {
          display: block;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          border: 1px solid #fff;
          background-image: url(../../img/common/arrow_w.png);
          background-size: 13px 20px;
          background-repeat: no-repeat;
          background-position: center center;
          position: absolute;
          top: calc(50% - 30px);
          z-index: 1;
          cursor: pointer;
          &.prev-arrow {
            transform: rotate(180deg);
            left: 30px;
          }
          &.next-arrow {
            right: 30px;
          }
        }
        .slick-dots {
          display: flex;
          position: absolute;
          bottom: 28px;
          left: 30px;
          li {
            width: 10px;
            height: 10px;
            margin: 0 7px;
            button {
              display: block;
              width: 100%;
              height: 100%;
              background-color: #ededed;
              border-radius: 50%;
              font-size: 0;
              color: transparent;
              outline: none;
            }
            &:first-of-type {
              margin-left: 0;
            }
            &:last-of-type {
              margin-right: 0;
            }
            &.slick-active {
              transform: scale(1.8);
              button {
                background-color: #e84735;
              }
            }
          }
        }
        .slider_content {
          width: 100%;
          position: relative;
          .img_blk {
            img {
              display: block;
              width: 100%;
            }
            video {
              display: block;
              width: 100%;
            }
          }
          .txt_blk {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #fff;
            position: absolute;
            bottom: 85px;
            left: 0;
            right: 0;
            margin: 0 auto;
            .slider_name {
              font-size: 3.6rem;
              font-weight: bold;
              line-height: 1.3;
              text-align: center;
              letter-spacing: .1em;
              margin-bottom: 50px;
            }
            .slider_btn {
              width: 260px;
              height: 54px;
              text-align: center;
              a {
                display: block;
                width: 100%;
                font-size: 1.8rem;
                font-weight: bold;
                line-height: 54px;
                letter-spacing: .1em;
                border: 1px solid #fff;
                &:hover {
                  opacity: 1;
                  background-color: #fff;
                  color: #000;
                }
              }
            }
          }
        }
      }
    }
    //注目の製品
    .attention_items {
      width: 100%;
      max-width: 1592px;
      margin: 0 auto 100px;
      .ttl01 {
        margin-bottom: 28px;
      }
      .attention_items_slider {
        width: 100%;
        max-width: 1461px;
        margin: 0 auto 60px;
        .slick-slide {
          margin: 0 34px;
          a {
            display: block;
          }
          .img_blk {
            width: 100%;
            margin-bottom: 14px;
            img {
              display: block;
              width: 100%;
            }
          }
          .txt_blk {
            span {
              display: block;
            }
            .item_name {
              font-size: 1.4rem;
              line-height: 1.4;
            }
            .item_price {
              font-size: 1.4rem;
              line-height: 1.4;
            }
          }
        }
        .slick-arrow {
          display: block;
          width: 16px;
          height: 26px;
          background-image: url(../../img/common/arrow_b.png);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          position: absolute;
          top: 100px;
          z-index: 1;
          cursor: pointer;
          &.prev-arrow {
            transform: rotate(180deg);
            left: -48px;
          }
          &.next-arrow {
            right: -48px;
          }
        }
      }
    }
    //キャンペーン
    .campaign {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto 46px;
      .ttl01 {
        margin-bottom: 30px;
      }
      .campaign_list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          width: 100%;
          max-width: 580px;
          margin-bottom: 40px;
          a {
            display: block;
          }
          .img_blk {
            width: 100%;
            margin-bottom: 20px;
            img {
              display: block;
              width: 100%;
            }
          }
          .txt_blk {
            .campaign_name {
              display: block;
              font-size: 1.4rem;
              font-weight: bold;
              text-align: center;
            }
          }
        }
      }
    }
    //特集
    .feature {
      width: 100%;
      margin: 0 auto 104px;
      background-color: #eeeeee;
      padding: 65px 0 85px;
      .ttl01 {
        margin-bottom: 24px;
      }
      .feature_list_wrap {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        position: relative;
      }
      .feature_list {
        width: 100%;
        margin: 0 auto;
        &:first-of-type {
          max-width: 32%;
          position: absolute;
          top: 0;
          left: 0;
          li {
            width: 100%;
            max-width: 100%;
          }
        }
        &:last-of-type {
          font-size: 0;
          li {
            display: inline-block;
            vertical-align: top;
            &:first-of-type {
              margin-left: 34%;
            }
            &:nth-of-type(3n) {
              margin-right: 2% !important;
            }
            &:nth-of-type(3n + 2) {
              margin-right: 0 !important;
            }
          }
        }
        li {
          width: 100%;
          max-width: 32%;
          margin-right: 2%;
          margin-bottom: 56px;
          &:nth-of-type(3n) {
            margin-right: 0;
          }
          .img_blk {
            width: 100%;
            margin-bottom: 20px;
            position: relative;
            img {
              display: block;
              width: 100%;
            }
            .hover_blk {
              opacity: 0;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background-color: rgba(0,0,0,.75);
              display: flex;
              align-items: center;
              justify-content: center;
              transition-duration: .2s;
            }
          }
          .txt_blk {
            .feature_name {
              display: block;
              font-size: 1.4rem;
              font-weight: bold;
              text-align: center;
              letter-spacing: .1em;
            }
          }
          &:hover {
            .img_blk {
              .hover_blk {
                opacity: 1;
              }
            }
          }
        }
      }
    }
    //ランキング
    .ranking_items {
      width: 100%;
      max-width: 1592px;
      margin: 0 auto 100px;
      .ttl01 {
        margin-bottom: 20px;
      }
      .ranking_items_slider {
        width: 100%;
        max-width: 1461px;
        margin: 0 auto 50px;
        .slick-slide {
          margin: 0 34px;
          padding-top: 8px;
          a {
            display: block;
          }
          .img_blk {
            width: 100%;
            margin-bottom: 14px;
            position: relative;
            img {
              display: block;
              width: 100%;
            }
            .ranking_icon {
              width: 40px;
              height: 40px;
              background-color: #000;
              color: #fff;
              font-size: 2.2rem;
              line-height: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              position: absolute;
              top: -8px;
              left: -8px;
              &.ranking_1st {
                background-color: #ac8959;
              }
              &.ranking_2nd {
                background-color: #7d7d7d;
              }
              &.ranking_3rd {
                background-color: #643b14;
              }
            }
          }
          .txt_blk {
            span {
              display: block;
            }
            .item_name {
              font-size: 1.4rem;
              line-height: 1.4;
            }
            .item_price {
              font-size: 1.4rem;
              line-height: 1.4;
            }
          }
        }
        .slick-arrow {
          display: block;
          width: 16px;
          height: 26px;
          background-image: url(../../img/common/arrow_b.png);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          position: absolute;
          top: 108px;
          z-index: 1;
          cursor: pointer;
          &.prev-arrow {
            transform: rotate(180deg);
            left: -48px;
          }
          &.next-arrow {
            right: -48px;
          }
        }
      }
    }
    //最新レビュー
    // .new_reviews {
    //   width: 100%;
    //   margin: 0 auto 104px;
    //   background-color: #eeeeee;
    //   padding: 65px 0 0;
    //   .ttl01 {
    //     margin-bottom: 24px;
    //   }
    //   .new_reviews_slider {
    //     .yotpo-reviews-carousel {
    //       .carousel-display-wrapper {
    //         .yotpo-carousel {
    //           .single-carousel {
    //             .small-version {

    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    //チェックしたアイテム
    .checked_items {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto 105px;
      .ttl01 {
        margin-bottom: 26px;
      }
      .checked_items_list {
        display: flex;
        li {
          width: 180px;
          margin-right: 24px;
          a {
            display: block;
            .img_blk {
              img {
                display: block;
                width: 100%;
              }
            }
          }
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
    //お気に入りアイテム
    .favorite_items {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto 88px;
      .ttl01 {
        margin-bottom: 26px;
      }
      .favorite_items_list {
        display: flex;
        li {
          width: 180px;
          margin-right: 24px;
          a {
            display: block;
            .img_blk {
              img {
                display: block;
                width: 100%;
              }
            }
          }
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
      .login_blk {
        padding-top: 60px;
        p {
          font-size: 1.4rem;
          text-align: center;
          line-height: 1.7;
          color: #797979;
          margin-bottom: 12px;
        }
      }
    }
    //お知らせ
    .news {
      width: 100%;
      max-width: 1117px;
      margin: 0 auto 125px;
      border-top: 1px solid #000;
      padding-top: 40px;
      .ttl01 {
        margin-bottom: 28px;
      }
      .news_list {
        width: 1020px;
        margin: 0 auto 62px;
        li {
          margin-bottom: 25px;
          a {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .news_icon {
              display: block;
              width: 100px;
              height: 33px;
              font-size: 1.2rem;
              text-align: center;
              font-weight: bold;
              line-height: 33px;
              letter-spacing: .1em;
              &.icon_item {
                color: #c02d28;
                background-color: #fff;
                border: 1px solid #c02d28;
              }
              &.icon_athlete {
                color: #fff;
                background-color: #000;
                border: 1px solid #000;
              }
              &.icon_campaign {
                color: #fff;
                background-color: #4ba649;
                border: 1px solid #4ba649;
                letter-spacing: 0;
              }
              &.icon_important {
                color: #fff;
                background-color: #c02d28;
                border: 1px solid #c02d28;
              }
              &.icon_companystore {
                color: #0086a2;
                background-color: #fff;
                border: 1px solid #0086a2;
              }
              &.icon_other {
                color: #000;
                background-color: #fff;
                border: 1px solid #000;
              }
            }
            .txt_blk {
              width: 904px;
              .news_date {
                display: block;
                position: relative;
                margin-bottom: 8px;
                .news_date_inner {
                  display: inline-block;
                  font-size: 1.4rem;
                  line-height: 1;
                  background-color: #fff;
                  padding-right: 22px;
                }
                &::after {
                  content: "";
                  display: block;
                  width: 100%;
                  height: 1px;
                  border-top: 1px dashed #000;
                  position: absolute;
                  top: .6em;
                  left: 0;
                  z-index: -1;
                }
              }
              .news_content {
                display: block;
                font-size: 1.4rem;
                letter-spacing: .05em;
              }
            }
          }
        }
      }
    }

    //特長
    .f_feature {
      width: 100%;
      background-color: #eeeeee;
      padding: 56px 0 60px;
      .ttl01 {
        margin-bottom: 40px;
      }
      .f_feature_wrap {
        width: 100%;
        max-width: 954px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .f_feature_blk {
          width: 210px;
          .f_feature_img {
            width: 100%;
            margin-bottom: 20px;
            img {
              display: block;
              width: 100%;
            }
          }
          h3 {
            font-size: 1.6rem;
            letter-spacing: .025em;
            line-height: 1;
            margin-bottom: 8px;
          }
          p {
            font-size: 1.4rem;
            line-height: 1.714;
          }
        }
      }
    }
  }
  //TOPのみフッター変更
  #footer {
    .f_wrap {
      .f_menu {
        border-top: none;
      }
    }
  }
}

@media only screen and (min-width: 1221px) and (max-width: 1600px) {
#top {
  #contents {
    //注目の製品
    .attention_items {
      max-width: 1160px;
      .attention_items_slider {
        max-width: 1160px;
        .slick-slide {
          margin: 0 20px;
        }
        .slick-arrow {
          top: 90px;
          &.prev-arrow {
            left: -20px;
          }
          &.next-arrow {
            right: -20px;
          }
        }
      }
    }
    //特集
    .feature {
      .feature_list_wrap {
        max-width: 1200px;
      }
      .feature_list {
        width: 100%;
        &:first-of-type {
          max-width: 32%;
          li {
            width: 100% !important;
            max-width: 100% !important;
          }
        }
        &:last-of-type {
          li {
            &:first-of-type {
              margin-left: 34%;
            }
            &:nth-of-type(3n) {
              margin-right: 2% !important;
            }
            &:nth-of-type(3n + 2) {
              margin-right: 0 !important;
            }
          }
        }
        li {
          max-width: 32%;
          margin-right: 2%;
        }
      }
    }
    //ランキング
    .ranking_items {
      max-width: 1160px;
      .ranking_items_slider {
        max-width: 1160px;
        .slick-slide {
          margin: 0 20px;
          padding-top: 8px;
        }
        .slick-arrow {
          top: 98px;
          &.prev-arrow {
            left: -20px;
          }
          &.next-arrow {
            right: -20px;
          }
        }
      }
    }
  }
}
}

@media only screen and (min-width: 1111px) and (max-width: 1220px) {
#top {
  #contents {
    margin-top: -120px;
  }
}
}

@media only screen and (min-width: 751px) and (max-width: 1110px) {
#top {
  #contents {
    margin-top: -81px;
  }
}
}

@media only screen and (min-width: 751px) and (max-width: 1220px) {
#top {
  #contents {
    //メインスライダー
    .mv_slider {
      margin-bottom: 122/1000*100vw;
      .slider_blk {
        .slick-arrow {
          width: 60/1000*100vw;
          height: 60/1000*100vw;
          background-image: url(../../img/common/arrow_w.png);
          background-size: 13/1000*100vw 20/1000*100vw;
          top: calc(50% - (60/1000*100vw));
          cursor: pointer;
          &.prev-arrow {
            transform: rotate(180deg);
            left: 30/1000*100vw;
          }
          &.next-arrow {
            right: 30/1000*100vw;
          }
        }
        .slick-dots {
          display: flex;
          position: absolute;
          bottom: 28/1000*100vw;
          left: 30/1000*100vw;
          li {
            width: 10/1000*100vw;
            height: 10/1000*100vw;
            margin: 0 7/1000*100vw;
          }
        }
        .slider_content {
          .txt_blk {
            bottom: 85/1000*100vw;
            .slider_name {
              font-size: 36/1000*100vw;
              margin-bottom: 50/1000*100vw;
            }
            .slider_btn {
              width: 260/1000*100vw;
              height: 54/1000*100vw;
              a {
                font-size: 18/1000*100vw;
                line-height: 54/1000*100vw;
              }
            }
          }
        }
      }
    }
    //注目の製品
    .attention_items {
      margin: 0 auto 100/1000*100vw;
      .ttl01 {
        margin-bottom: 28/1000*100vw;
      }
      .attention_items_slider {
        margin: 0 auto 60/1000*100vw;
        padding: 0 40/1000*100vw;
        .slick-slide {
          margin: 0 20/1000*100vw;
          .img_blk {
            margin-bottom: 14/1000*100vw;
          }
          .txt_blk {
            .item_name {
              font-size: 14/1000*100vw;
            }
            .item_price {
              font-size: 14/1000*100vw;
            }
          }
        }
        .slick-arrow {
          width: 16/1000*100vw;
          height: 26/1000*100vw;
          top: 84/1000*100vw;
          z-index: 1;
          cursor: pointer;
          &.prev-arrow {
            transform: rotate(180deg);
            left: 20/1000*100vw;
          }
          &.next-arrow {
            right: 20/1000*100vw;
          }
        }
      }
    }
    //キャンペーン
    .campaign {
      margin: 0 auto 46/1000*100vw;
      .ttl01 {
        margin-bottom: 30/1000*100vw;
      }
      .campaign_list {
        padding: 0 40/1000*100vw;
        li {
          width: 100%;
          max-width: 48%;
          margin-bottom: 40/1000*100vw;
          .img_blk {
            margin-bottom: 20/1000*100vw;
          }
          .txt_blk {
            .campaign_name {
              font-size: 14/1000*100vw;
            }
          }
        }
      }
    }
    //特集
    .feature {
      margin: 0 auto 104/1000*100vw;
      padding: 65/1000*100vw 0;
      .ttl01 {
        margin-bottom: 24px;
      }
      .feature_list_wrap {
        max-width: calc(100% - (40/1000*100vw));
        margin: 0 auto;
      }
      .feature_list {
        width: 100%;
        &:first-of-type {
          max-width: 32%;
          li {
            width: 100% !important;
            max-width: 100% !important;
          }
        }
        &:last-of-type {
          li {
            &:first-of-type {
              margin-left: 34%;
            }
            &:nth-of-type(3n) {
              margin-right: 2% !important;
            }
            &:nth-of-type(3n + 2) {
              margin-right: 0 !important;
            }
          }
        }
        li {
          max-width: 32%;
          margin-right: 2%;
          margin-right: 2%;
          margin-bottom: 56/1000*100vw;
          .img_blk {
            margin-bottom: 20/1000*100vw;
          }
          .txt_blk {
            .feature_name {
              font-size: 14/1000*100vw;
            }
          }
        }
      }
    }
    //ランキング
    .ranking_items {
      margin: 0 auto 100/1000*100vw;
      .ttl01 {
        margin-bottom: 20/1000*100vw;
      }
      .ranking_items_slider {
        margin: 0 auto 50/1000*100vw;
        padding: 0 40/1000*100vw;
        .slick-slide {
          margin: 0 20/1000*100vw;
          padding-top: 8/1000*100vw;
          .img_blk {
            margin-bottom: 14/1000*100vw;
            .ranking_icon {
              width: 40/1000*100vw;
              height: 40/1000*100vw;
              font-size: 22/1000*100vw;
              top: -8/1000*100vw;
              left: -8/1000*100vw;
            }
          }
          .txt_blk {
            .item_name {
              font-size: 14/1000*100vw;
            }
            .item_price {
              font-size: 14/1000*100vw;
            }
          }
        }
        .slick-arrow {
          width: 16/1000*100vw;
          height: 26/1000*100vw;
          top: 92/1000*100vw;
          &.prev-arrow {
            left: 20/1000*100vw;
          }
          &.next-arrow {
            right: 20/1000*100vw;
          }
        }
      }
    }
    //最新レビュー
    // .new_reviews {
    //   width: 100%;
    //   margin: 0 auto 104px;
    //   background-color: #eeeeee;
    //   padding: 65px 0 0;
    //   .ttl01 {
    //     margin-bottom: 24px;
    //   }
    //   .new_reviews_slider {
    //     .yotpo-reviews-carousel {
    //       .carousel-display-wrapper {
    //         .yotpo-carousel {
    //           .single-carousel {
    //             .small-version {

    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    //チェックしたアイテム
    .checked_items {
      margin: 0 auto 105/1000*100vw;
      .ttl01 {
        margin-bottom: 26/1000*100vw;
      }
      .checked_items_list {
        padding: 0 40/1000*100vw;
        li {
          width: 15%;
          margin-right: 2%;
        }
      }
    }
    //お気に入りアイテム
    .favorite_items {
      margin: 0 auto 88/1000*100vw;
      .ttl01 {
        margin-bottom: 26/1000*100vw;
      }
      .favorite_items_list {
        padding: 0 40/1000*100vw;
        li {
          width: 15%;
          margin-right: 2%;
        }
      }
      .login_blk {
        padding-top: 60/1000*100vw;
        p {
          font-size: 14/1000*100vw;
          margin-bottom: 12/1000*100vw;
        }
      }
    }
    //お知らせ
    .news {
      width: calc(100% - (80/1000*100vw));
      margin: 0 auto 125/1000*100vw;
      padding-top: 40/1000*100vw;
      .ttl01 {
        margin-bottom: 28/1000*100vw;
      }
      .news_list {
        width: 100%;
        margin: 0 auto 62/1000*100vw;
        li {
          margin-bottom: 25/1000*100vw;
          a {
            .news_icon {
              width: 100/1000*100vw;
              height: 40/1000*100vw;
              font-size: 12/1000*100vw;
              line-height: 40/1000*100vw;
            }
            .txt_blk {
              width: calc(100% - (120/1000*100vw));
              .news_date {
                margin-bottom: 8/1000*100vw;
                .news_date_inner {
                  font-size: 14/1000*100vw;
                  padding-right: 22/1000*100vw;
                }
                &::after {
                  top: .5em;
                }
              }
              .news_content {
                font-size: 14/1000*100vw;
              }
            }
          }
        }
      }
    }
    //特長
    .f_feature {
      padding: 56/1000*100vw 0 60/1000*100vw;
      .ttl01 {
        margin-bottom: 40/1000*100vw;
      }
      .f_feature_wrap {
        max-width: 954/1000*100vw;
        .f_feature_blk {
          width: 210/1000*100vw;
          .f_feature_img {
            margin-bottom: 20/1000*100vw;
          }
          h3 {
            font-size: 16/1000*100vw;
            margin-bottom: 8/1000*100vw;
          }
          p {
            font-size: 14/1000*100vw;
          }
        }
      }
    }
  }
  //TOPのみフッター変更
  #footer {
    margin-top: 0;
  }
}
}

@media only screen and (max-width: 750px) {
#top {
  #contents {
    margin-top: -16vw;
    //メインスライダー
    .mv_slider {
      width: 100%;
      margin-bottom: 86/750*100vw;
      overflow-x: hidden;
      .slider_blk {
        .slick-arrow {
          width: 80/750*100vw;
          height: 80/750*100vw;
          background-size: 17/750*100vw 27/750*100vw;
          background-position: left 18/750*100vw center;
          top: calc(50% - (40/750*100vw));
          cursor: pointer;
          &.prev-arrow {
            left: -40/750*100vw;
          }
          &.next-arrow {
            right: -40/750*100vw;
          }
        }
        .slick-dots {
          bottom: 34/750*100vw;
          left: 26/750*100vw;
          li {
            width: 14/750*100vw;
            height: 14/750*100vw;
            margin: 0 8/750*100vw;
          }
        }
        .slider_content {
          .txt_blk {
            bottom: 80/750*100vw;
            .slider_name {
              font-size: 44/750*100vw;
              line-height: 1.36;
              margin-bottom: 32/750*100vw;
            }
            .slider_btn {
              width: 360/750*100vw;
              height: 75/750*100vw;
              a {
                font-size: 24/750*100vw;
                line-height: 75/750*100vw;
              }
            }
          }
        }
      }
    }
    //注目の製品
    .attention_items {
      margin: 0 auto 92/750*100vw;
      .ttl01 {
        margin-bottom: 30/750*100vw;
      }
      .attention_items_slider {
        margin: 0 auto 90/750*100vw;
        display: flex;
        width: 100%;
        overflow-x: scroll;
        &::after {
          content: "";
          display: block;
          width: 40/750*100vw;
          height: 1px;
          flex-shrink: 0;
        }
        li {
          flex-shrink: 0;
          width: 260/750*100vw;
          margin: 0 0 0 40/750*100vw;
          .img_blk {
            margin-bottom: 18/750*100vw;
            img {
              display: block;
              width: 100%;
            }
          }
          .txt_blk {
            span {
              display: block;
            }
            .item_name {
              font-size: 24/750*100vw;
              line-height: 1.33;
            }
            .item_price {
              font-size: 24/750*100vw;
              line-height: 1.33;
            }
          }
        }
      }
    }
    //キャンペーン
    .campaign {
      width: 100%;
      padding: 0 40/750*100vw;
      margin: 0 auto 0;
      .ttl01 {
        margin-bottom: 32/750*100vw;
      }
      .campaign_list {
        display: block;
        li {
          max-width: 100%;
          margin-bottom: 70/750*100vw;
          .img_blk {
            margin-bottom: 30/750*100vw;
          }
          .txt_blk {
            .campaign_name {
              font-size: 24/750*100vw;
            }
          }
        }
      }
    }
    //特集
    .feature {
      width: 100%;
      margin: 0 auto 70/750*100vw;
      background-color: #eeeeee;
      padding: 50/750*100vw 0 68/750*100vw;
      .ttl01 {
        margin-bottom: 30/750*100vw;
      }

      .feature_list_wrap {
        width: 100%;
      }
      .feature_list {
        display: block;
        &:first-of-type {
          position: static;
          max-width: 100%;
        }
        &:last-of-type {
          li {
            &:first-of-type {
              margin-left: 40/750*100vw;
            }
            &:nth-of-type(3n) {
              margin-right: 0 !important;
            }
          }
        }
        li {
          max-width: 100%;
          margin-right: 0;
          margin-bottom: 78/750*100vw;
          .img_blk {
            margin-bottom: 42/750*100vw;
            .hover_blk {
              display: none;
            }
          }
          .txt_blk {
            .feature_name {
              font-size: 24/750*100vw;
            }
          }
        }
        &:last-of-type {
          display: flex;
          flex-wrap: initial;
          overflow-x: scroll;
          margin-bottom: 78/750*100vw;
          &::after {
            content: "";
            display: block;
            width: 40/750*100vw;
            height: 1px;
            flex-shrink: 0;
          }
          li {
            flex-shrink: 0;
            width: 497/750*100vw;
            margin-left: 40/750*100vw;
            margin-bottom: 0;
            .img_blk {
              margin-bottom: 26/750*100vw;
            }
            .txt_blk {
              .feature_name {
                text-align: left;
              }
            }
          }
        }
      }
    }
    //ランキング
    .ranking_items {
      margin: 0 auto 90/750*100vw;
      .ttl01 {
        margin-bottom: 18/750*100vw;
      }
      .ranking_items_slider {
        display: flex;
        overflow-x: scroll;
        margin: 0 auto 90/750*100vw;
        &::after {
          content: "";
          display: block;
          width: 40/750*100vw;
          height: 1px;
          flex-shrink: 0;
        }
        li {
          width: 260/750*100vw;
          flex-shrink: 0;
          margin: 0 0 0 40/750*100vw;
          padding-top: 10/750*100vw;
          .img_blk {
            width: 100%;
            margin-bottom: 20/750*100vw;
            position: relative;
            img {
              display: block;
              width: 100%;
            }
            .ranking_icon {
              width: 49/750*100vw;
              height: 49/750*100vw;
              background-color: #000;
              color: #fff;
              font-size: 26/750*100vw;
              line-height: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              position: absolute;
              top: -10/750*100vw;
              left: -10/750*100vw;
              &.ranking_1st {
                background-color: #ac8959;
              }
              &.ranking_2nd {
                background-color: #7d7d7d;
              }
              &.ranking_3rd {
                background-color: #643b14;
              }
            }
          }
          .txt_blk {
            span {
              display: block;
            }
            .item_name {
              font-size: 24/750*100vw;
              line-height: 1.33;
            }
            .item_price {
              font-size: 24/750*100vw;
              line-height: 1.33;
            }
          }
        }
      }
    }
    //最新レビュー
    // .new_reviews {
    //   width: 100%;
    //   margin: 0 auto 104px;
    //   background-color: #eeeeee;
    //   padding: 65px 0 0;
    //   .ttl01 {
    //     margin-bottom: 24px;
    //   }
    //   .new_reviews_slider {
    //     .yotpo-reviews-carousel {
    //       .carousel-display-wrapper {
    //         .yotpo-carousel {
    //           .single-carousel {
    //             .small-version {

    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    //チェックしたアイテム
    .checked_items {
      margin: 0 auto 106/750*100vw;
      .ttl01 {
        margin-bottom: 34/750*100vw;
      }
      .checked_items_list {
        overflow-x: scroll;
        &::after {
          content: "";
          display: block;
          width: 40/750*100vw;
          height: 1px;
          flex-shrink: 0;
        }
        li {
          flex-shrink: 0;
          width: 180/750*100vw;
          margin-right: 0;
          margin-left: 23/750*100vw;
          &:first-of-type {
            margin-left: 40/750*100vw;
          }
        }
      }
    }
    //お気に入りアイテム
    .favorite_items {
      margin: 0 auto 120/750*100vw;
      .ttl01 {
        margin-bottom: 34/750*100vw;
      }
      .favorite_items_list {
        overflow-x: scroll;
        &::after {
          content: "";
          display: block;
          width: 40/750*100vw;
          height: 1px;
          flex-shrink: 0;
        }
        li {
          flex-shrink: 0;
          width: 180/750*100vw;
          margin-right: 0;
          margin-left: 23/750*100vw;
          &:first-of-type {
            margin-left: 40/750*100vw;
          }
        }
      }
      .login_blk {
        padding-top: 60/750*100vw;
        p {
          font-size: 24/750*100vw;
          margin-bottom: 24/750*100vw;
        }
      }
    }
    //お知らせ
    .news {
      width: 100%;
      margin: 0 auto 90/750*100vw;
      border-top: none;
      padding-top: 0;
      &::before {
        content: "";
        display: block;
        width: 480/750*100vw;
        height: 1px;
        margin: 0 auto 50/750*100vw;
        background-color: #000;
      }
      .ttl01 {
        margin-bottom: 30/750*100vw;
      }
      .news_list {
        width: 100%;
        padding: 0 80/750*100vw;
        margin: 0 auto 74/750*100vw;
        li {
          margin-bottom: 42/750*100vw;
          padding-bottom: 42/750*100vw;
          border-bottom: 1px dashed #000;
          a {
            display: block;
            position: relative;
            .news_icon {
              width: 150/750*100vw;
              height: 50/750*100vw;
              font-size: 18/750*100vw;
              line-height: 50/750*100vw;
              margin-bottom: 18/750*100vw;
            }
            .txt_blk {
              width: 100%;
              .news_date {
                display: block;
                position: absolute;
                top: 12/750*100vw;
                left: 170/750*100vw;
                margin-bottom: 0;
                .news_date_inner {
                  font-size: 22/750*100vw;
                  background-color: transparent;
                  padding-right: 0;
                }
                &::after {
                  content: none;
                }
              }
              .news_content {
                font-size: 22/750*100vw;
              }
            }
          }
        }
      }
    }

    //特長
    .f_feature {
      padding: 58/750*100vw 0 60/750*100vw;
      .ttl01 {
        margin-bottom: 34/750*100vw;
        span {
          display: none;
        }
      }
      .f_feature_wrap {
        display: block;
        white-space: nowrap;
        font-size: 0;
        overflow-x: scroll;
        .f_feature_blk {
          display: inline-block;
          width: 300/750*100vw;
          margin: 0 13/750*100vw;
          vertical-align: top;
          &:first-of-type {
            margin-left: 40/750*100vw;
          }
          &:last-of-type {
            margin-right: 40/750*100vw;
          }
          .f_feature_img {
            margin-bottom: 26/750*100vw;
          }
          h3 {
            font-size: 23/750*100vw;
            margin-bottom: 14/750*100vw;
            white-space: normal;
          }
          p {
            font-size: 20/750*100vw;
            white-space: normal;
          }
        }
      }
    }
  }
  //TOPのみフッター変更
  #footer {
    margin-top: 0;
  }
}
}