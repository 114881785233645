#top #contents {
  width: 100%;
  margin-top: -120px;
}

#top #contents .mv_slider {
  width: 100%;
  margin-bottom: 122px;
}

#top #contents .mv_slider .slider_blk {
  width: 100%;
  position: relative;
  opacity: 0;
}

#top #contents .mv_slider .slider_blk.slick-initialized {
  opacity: 1;
}

#top #contents .mv_slider .slider_blk .slick-arrow {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #fff;
  background-image: url(../../img/common/arrow_w.png);
  background-size: 13px 20px;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: calc(50% - 30px);
  z-index: 1;
  cursor: pointer;
}

#top #contents .mv_slider .slider_blk .slick-arrow.prev-arrow {
  transform: rotate(180deg);
  left: 30px;
}

#top #contents .mv_slider .slider_blk .slick-arrow.next-arrow {
  right: 30px;
}

#top #contents .mv_slider .slider_blk .slick-dots {
  display: flex;
  position: absolute;
  bottom: 28px;
  left: 30px;
}

#top #contents .mv_slider .slider_blk .slick-dots li {
  width: 10px;
  height: 10px;
  margin: 0 7px;
}

#top #contents .mv_slider .slider_blk .slick-dots li button {
  display: block;
  width: 100%;
  height: 100%;
  background-color: #ededed;
  border-radius: 50%;
  font-size: 0;
  color: transparent;
  outline: none;
}

#top #contents .mv_slider .slider_blk .slick-dots li:first-of-type {
  margin-left: 0;
}

#top #contents .mv_slider .slider_blk .slick-dots li:last-of-type {
  margin-right: 0;
}

#top #contents .mv_slider .slider_blk .slick-dots li.slick-active {
  transform: scale(1.8);
}

#top #contents .mv_slider .slider_blk .slick-dots li.slick-active button {
  background-color: #e84735;
}

#top #contents .mv_slider .slider_blk .slider_content {
  width: 100%;
  position: relative;
}

#top #contents .mv_slider .slider_blk .slider_content .img_blk img {
  display: block;
  width: 100%;
}

#top #contents .mv_slider .slider_blk .slider_content .img_blk video {
  display: block;
  width: 100%;
}

#top #contents .mv_slider .slider_blk .slider_content .txt_blk {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: absolute;
  bottom: 85px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

#top #contents .mv_slider .slider_blk .slider_content .txt_blk .slider_name {
  font-size: 3.6rem;
  font-weight: bold;
  line-height: 1.3;
  text-align: center;
  letter-spacing: .1em;
  margin-bottom: 50px;
}

#top #contents .mv_slider .slider_blk .slider_content .txt_blk .slider_btn {
  width: 260px;
  height: 54px;
  text-align: center;
}

#top #contents .mv_slider .slider_blk .slider_content .txt_blk .slider_btn a {
  display: block;
  width: 100%;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 54px;
  letter-spacing: .1em;
  border: 1px solid #fff;
}

#top #contents .mv_slider .slider_blk .slider_content .txt_blk .slider_btn a:hover {
  opacity: 1;
  background-color: #fff;
  color: #000;
}

#top #contents .attention_items {
  width: 100%;
  max-width: 1592px;
  margin: 0 auto 100px;
}

#top #contents .attention_items .ttl01 {
  margin-bottom: 28px;
}

#top #contents .attention_items .attention_items_slider {
  width: 100%;
  max-width: 1461px;
  margin: 0 auto 60px;
}

#top #contents .attention_items .attention_items_slider .slick-slide {
  margin: 0 34px;
}

#top #contents .attention_items .attention_items_slider .slick-slide a {
  display: block;
}

#top #contents .attention_items .attention_items_slider .slick-slide .img_blk {
  width: 100%;
  margin-bottom: 14px;
}

#top #contents .attention_items .attention_items_slider .slick-slide .img_blk img {
  display: block;
  width: 100%;
}

#top #contents .attention_items .attention_items_slider .slick-slide .txt_blk span {
  display: block;
}

#top #contents .attention_items .attention_items_slider .slick-slide .txt_blk .item_name {
  font-size: 1.4rem;
  line-height: 1.4;
}

#top #contents .attention_items .attention_items_slider .slick-slide .txt_blk .item_price {
  font-size: 1.4rem;
  line-height: 1.4;
}

#top #contents .attention_items .attention_items_slider .slick-arrow {
  display: block;
  width: 16px;
  height: 26px;
  background-image: url(../../img/common/arrow_b.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 100px;
  z-index: 1;
  cursor: pointer;
}

#top #contents .attention_items .attention_items_slider .slick-arrow.prev-arrow {
  transform: rotate(180deg);
  left: -48px;
}

#top #contents .attention_items .attention_items_slider .slick-arrow.next-arrow {
  right: -48px;
}

#top #contents .campaign {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 46px;
}

#top #contents .campaign .ttl01 {
  margin-bottom: 30px;
}

#top #contents .campaign .campaign_list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

#top #contents .campaign .campaign_list li {
  width: 100%;
  max-width: 580px;
  margin-bottom: 40px;
}

#top #contents .campaign .campaign_list li a {
  display: block;
}

#top #contents .campaign .campaign_list li .img_blk {
  width: 100%;
  margin-bottom: 20px;
}

#top #contents .campaign .campaign_list li .img_blk img {
  display: block;
  width: 100%;
}

#top #contents .campaign .campaign_list li .txt_blk .campaign_name {
  display: block;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
}

#top #contents .feature {
  width: 100%;
  margin: 0 auto 104px;
  background-color: #eeeeee;
  padding: 65px 0 85px;
}

#top #contents .feature .ttl01 {
  margin-bottom: 24px;
}

#top #contents .feature .feature_list_wrap {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

#top #contents .feature .feature_list {
  width: 100%;
  margin: 0 auto;
}

#top #contents .feature .feature_list:first-of-type {
  max-width: 32%;
  position: absolute;
  top: 0;
  left: 0;
}

#top #contents .feature .feature_list:first-of-type li {
  width: 100%;
  max-width: 100%;
}

#top #contents .feature .feature_list:last-of-type {
  font-size: 0;
}

#top #contents .feature .feature_list:last-of-type li {
  display: inline-block;
  vertical-align: top;
}

#top #contents .feature .feature_list:last-of-type li:first-of-type {
  margin-left: 34%;
}

#top #contents .feature .feature_list:last-of-type li:nth-of-type(3n) {
  margin-right: 2% !important;
}

#top #contents .feature .feature_list:last-of-type li:nth-of-type(3n + 2) {
  margin-right: 0 !important;
}

#top #contents .feature .feature_list li {
  width: 100%;
  max-width: 32%;
  margin-right: 2%;
  margin-bottom: 56px;
}

#top #contents .feature .feature_list li:nth-of-type(3n) {
  margin-right: 0;
}

#top #contents .feature .feature_list li .img_blk {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
}

#top #contents .feature .feature_list li .img_blk img {
  display: block;
  width: 100%;
}

#top #contents .feature .feature_list li .img_blk .hover_blk {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: .2s;
}

#top #contents .feature .feature_list li .txt_blk .feature_name {
  display: block;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
  letter-spacing: .1em;
}

#top #contents .feature .feature_list li:hover .img_blk .hover_blk {
  opacity: 1;
}

#top #contents .ranking_items {
  width: 100%;
  max-width: 1592px;
  margin: 0 auto 100px;
}

#top #contents .ranking_items .ttl01 {
  margin-bottom: 20px;
}

#top #contents .ranking_items .ranking_items_slider {
  width: 100%;
  max-width: 1461px;
  margin: 0 auto 50px;
}

#top #contents .ranking_items .ranking_items_slider .slick-slide {
  margin: 0 34px;
  padding-top: 8px;
}

#top #contents .ranking_items .ranking_items_slider .slick-slide a {
  display: block;
}

#top #contents .ranking_items .ranking_items_slider .slick-slide .img_blk {
  width: 100%;
  margin-bottom: 14px;
  position: relative;
}

#top #contents .ranking_items .ranking_items_slider .slick-slide .img_blk img {
  display: block;
  width: 100%;
}

#top #contents .ranking_items .ranking_items_slider .slick-slide .img_blk .ranking_icon {
  width: 40px;
  height: 40px;
  background-color: #000;
  color: #fff;
  font-size: 2.2rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: -8px;
  left: -8px;
}

#top #contents .ranking_items .ranking_items_slider .slick-slide .img_blk .ranking_icon.ranking_1st {
  background-color: #ac8959;
}

#top #contents .ranking_items .ranking_items_slider .slick-slide .img_blk .ranking_icon.ranking_2nd {
  background-color: #7d7d7d;
}

#top #contents .ranking_items .ranking_items_slider .slick-slide .img_blk .ranking_icon.ranking_3rd {
  background-color: #643b14;
}

#top #contents .ranking_items .ranking_items_slider .slick-slide .txt_blk span {
  display: block;
}

#top #contents .ranking_items .ranking_items_slider .slick-slide .txt_blk .item_name {
  font-size: 1.4rem;
  line-height: 1.4;
}

#top #contents .ranking_items .ranking_items_slider .slick-slide .txt_blk .item_price {
  font-size: 1.4rem;
  line-height: 1.4;
}

#top #contents .ranking_items .ranking_items_slider .slick-arrow {
  display: block;
  width: 16px;
  height: 26px;
  background-image: url(../../img/common/arrow_b.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 108px;
  z-index: 1;
  cursor: pointer;
}

#top #contents .ranking_items .ranking_items_slider .slick-arrow.prev-arrow {
  transform: rotate(180deg);
  left: -48px;
}

#top #contents .ranking_items .ranking_items_slider .slick-arrow.next-arrow {
  right: -48px;
}

#top #contents .checked_items {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 105px;
}

#top #contents .checked_items .ttl01 {
  margin-bottom: 26px;
}

#top #contents .checked_items .checked_items_list {
  display: flex;
}

#top #contents .checked_items .checked_items_list li {
  width: 180px;
  margin-right: 24px;
}

#top #contents .checked_items .checked_items_list li a {
  display: block;
}

#top #contents .checked_items .checked_items_list li a .img_blk img {
  display: block;
  width: 100%;
}

#top #contents .checked_items .checked_items_list li:last-of-type {
  margin-right: 0;
}

#top #contents .favorite_items {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 88px;
}

#top #contents .favorite_items .ttl01 {
  margin-bottom: 26px;
}

#top #contents .favorite_items .favorite_items_list {
  display: flex;
}

#top #contents .favorite_items .favorite_items_list li {
  width: 180px;
  margin-right: 24px;
}

#top #contents .favorite_items .favorite_items_list li a {
  display: block;
}

#top #contents .favorite_items .favorite_items_list li a .img_blk img {
  display: block;
  width: 100%;
}

#top #contents .favorite_items .favorite_items_list li:last-of-type {
  margin-right: 0;
}

#top #contents .favorite_items .login_blk {
  padding-top: 60px;
}

#top #contents .favorite_items .login_blk p {
  font-size: 1.4rem;
  text-align: center;
  line-height: 1.7;
  color: #797979;
  margin-bottom: 12px;
}

#top #contents .news {
  width: 100%;
  max-width: 1117px;
  margin: 0 auto 125px;
  border-top: 1px solid #000;
  padding-top: 40px;
}

#top #contents .news .ttl01 {
  margin-bottom: 28px;
}

#top #contents .news .news_list {
  width: 1020px;
  margin: 0 auto 62px;
}

#top #contents .news .news_list li {
  margin-bottom: 25px;
}

#top #contents .news .news_list li a {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#top #contents .news .news_list li a .news_icon {
  display: block;
  width: 100px;
  height: 33px;
  font-size: 1.2rem;
  text-align: center;
  font-weight: bold;
  line-height: 33px;
  letter-spacing: .1em;
}

#top #contents .news .news_list li a .news_icon.icon_item {
  color: #c02d28;
  background-color: #fff;
  border: 1px solid #c02d28;
}

#top #contents .news .news_list li a .news_icon.icon_athlete {
  color: #fff;
  background-color: #000;
  border: 1px solid #000;
}

#top #contents .news .news_list li a .news_icon.icon_campaign {
  color: #fff;
  background-color: #4ba649;
  border: 1px solid #4ba649;
  letter-spacing: 0;
}

#top #contents .news .news_list li a .news_icon.icon_important {
  color: #fff;
  background-color: #c02d28;
  border: 1px solid #c02d28;
}

#top #contents .news .news_list li a .news_icon.icon_companystore {
  color: #0086a2;
  background-color: #fff;
  border: 1px solid #0086a2;
}

#top #contents .news .news_list li a .news_icon.icon_other {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}

#top #contents .news .news_list li a .txt_blk {
  width: 904px;
}

#top #contents .news .news_list li a .txt_blk .news_date {
  display: block;
  position: relative;
  margin-bottom: 8px;
}

#top #contents .news .news_list li a .txt_blk .news_date .news_date_inner {
  display: inline-block;
  font-size: 1.4rem;
  line-height: 1;
  background-color: #fff;
  padding-right: 22px;
}

#top #contents .news .news_list li a .txt_blk .news_date::after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  border-top: 1px dashed #000;
  position: absolute;
  top: .6em;
  left: 0;
  z-index: -1;
}

#top #contents .news .news_list li a .txt_blk .news_content {
  display: block;
  font-size: 1.4rem;
  letter-spacing: .05em;
}

#top #contents .f_feature {
  width: 100%;
  background-color: #eeeeee;
  padding: 56px 0 60px;
}

#top #contents .f_feature .ttl01 {
  margin-bottom: 40px;
}

#top #contents .f_feature .f_feature_wrap {
  width: 100%;
  max-width: 954px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

#top #contents .f_feature .f_feature_wrap .f_feature_blk {
  width: 210px;
}

#top #contents .f_feature .f_feature_wrap .f_feature_blk .f_feature_img {
  width: 100%;
  margin-bottom: 20px;
}

#top #contents .f_feature .f_feature_wrap .f_feature_blk .f_feature_img img {
  display: block;
  width: 100%;
}

#top #contents .f_feature .f_feature_wrap .f_feature_blk h3 {
  font-size: 1.6rem;
  letter-spacing: .025em;
  line-height: 1;
  margin-bottom: 8px;
}

#top #contents .f_feature .f_feature_wrap .f_feature_blk p {
  font-size: 1.4rem;
  line-height: 1.714;
}

#top #footer .f_wrap .f_menu {
  border-top: none;
}

@media only screen and (min-width: 1221px) and (max-width: 1600px) {
  #top #contents .attention_items {
    max-width: 1160px;
  }
  #top #contents .attention_items .attention_items_slider {
    max-width: 1160px;
  }
  #top #contents .attention_items .attention_items_slider .slick-slide {
    margin: 0 20px;
  }
  #top #contents .attention_items .attention_items_slider .slick-arrow {
    top: 90px;
  }
  #top #contents .attention_items .attention_items_slider .slick-arrow.prev-arrow {
    left: -20px;
  }
  #top #contents .attention_items .attention_items_slider .slick-arrow.next-arrow {
    right: -20px;
  }
  #top #contents .feature .feature_list_wrap {
    max-width: 1200px;
  }
  #top #contents .feature .feature_list {
    width: 100%;
  }
  #top #contents .feature .feature_list:first-of-type {
    max-width: 32%;
  }
  #top #contents .feature .feature_list:first-of-type li {
    width: 100% !important;
    max-width: 100% !important;
  }
  #top #contents .feature .feature_list:last-of-type li:first-of-type {
    margin-left: 34%;
  }
  #top #contents .feature .feature_list:last-of-type li:nth-of-type(3n) {
    margin-right: 2% !important;
  }
  #top #contents .feature .feature_list:last-of-type li:nth-of-type(3n + 2) {
    margin-right: 0 !important;
  }
  #top #contents .feature .feature_list li {
    max-width: 32%;
    margin-right: 2%;
  }
  #top #contents .ranking_items {
    max-width: 1160px;
  }
  #top #contents .ranking_items .ranking_items_slider {
    max-width: 1160px;
  }
  #top #contents .ranking_items .ranking_items_slider .slick-slide {
    margin: 0 20px;
    padding-top: 8px;
  }
  #top #contents .ranking_items .ranking_items_slider .slick-arrow {
    top: 98px;
  }
  #top #contents .ranking_items .ranking_items_slider .slick-arrow.prev-arrow {
    left: -20px;
  }
  #top #contents .ranking_items .ranking_items_slider .slick-arrow.next-arrow {
    right: -20px;
  }
}

@media only screen and (min-width: 1111px) and (max-width: 1220px) {
  #top #contents {
    margin-top: -120px;
  }
}

@media only screen and (min-width: 751px) and (max-width: 1110px) {
  #top #contents {
    margin-top: -81px;
  }
}

@media only screen and (min-width: 751px) and (max-width: 1220px) {
  #top #contents .mv_slider {
    margin-bottom: 12.2vw;
  }
  #top #contents .mv_slider .slider_blk .slick-arrow {
    width: 6vw;
    height: 6vw;
    background-image: url(../../img/common/arrow_w.png);
    background-size: 1.3vw 2vw;
    top: calc(50% - (60/1000*100vw));
    cursor: pointer;
  }
  #top #contents .mv_slider .slider_blk .slick-arrow.prev-arrow {
    transform: rotate(180deg);
    left: 3vw;
  }
  #top #contents .mv_slider .slider_blk .slick-arrow.next-arrow {
    right: 3vw;
  }
  #top #contents .mv_slider .slider_blk .slick-dots {
    display: flex;
    position: absolute;
    bottom: 2.8vw;
    left: 3vw;
  }
  #top #contents .mv_slider .slider_blk .slick-dots li {
    width: 1vw;
    height: 1vw;
    margin: 0 0.7vw;
  }
  #top #contents .mv_slider .slider_blk .slider_content .txt_blk {
    bottom: 8.5vw;
  }
  #top #contents .mv_slider .slider_blk .slider_content .txt_blk .slider_name {
    font-size: 3.6vw;
    margin-bottom: 5vw;
  }
  #top #contents .mv_slider .slider_blk .slider_content .txt_blk .slider_btn {
    width: 26vw;
    height: 5.4vw;
  }
  #top #contents .mv_slider .slider_blk .slider_content .txt_blk .slider_btn a {
    font-size: 1.8vw;
    line-height: 5.4vw;
  }
  #top #contents .attention_items {
    margin: 0 auto 10vw;
  }
  #top #contents .attention_items .ttl01 {
    margin-bottom: 2.8vw;
  }
  #top #contents .attention_items .attention_items_slider {
    margin: 0 auto 6vw;
    padding: 0 4vw;
  }
  #top #contents .attention_items .attention_items_slider .slick-slide {
    margin: 0 2vw;
  }
  #top #contents .attention_items .attention_items_slider .slick-slide .img_blk {
    margin-bottom: 1.4vw;
  }
  #top #contents .attention_items .attention_items_slider .slick-slide .txt_blk .item_name {
    font-size: 1.4vw;
  }
  #top #contents .attention_items .attention_items_slider .slick-slide .txt_blk .item_price {
    font-size: 1.4vw;
  }
  #top #contents .attention_items .attention_items_slider .slick-arrow {
    width: 1.6vw;
    height: 2.6vw;
    top: 8.4vw;
    z-index: 1;
    cursor: pointer;
  }
  #top #contents .attention_items .attention_items_slider .slick-arrow.prev-arrow {
    transform: rotate(180deg);
    left: 2vw;
  }
  #top #contents .attention_items .attention_items_slider .slick-arrow.next-arrow {
    right: 2vw;
  }
  #top #contents .campaign {
    margin: 0 auto 4.6vw;
  }
  #top #contents .campaign .ttl01 {
    margin-bottom: 3vw;
  }
  #top #contents .campaign .campaign_list {
    padding: 0 4vw;
  }
  #top #contents .campaign .campaign_list li {
    width: 100%;
    max-width: 48%;
    margin-bottom: 4vw;
  }
  #top #contents .campaign .campaign_list li .img_blk {
    margin-bottom: 2vw;
  }
  #top #contents .campaign .campaign_list li .txt_blk .campaign_name {
    font-size: 1.4vw;
  }
  #top #contents .feature {
    margin: 0 auto 10.4vw;
    padding: 6.5vw 0;
  }
  #top #contents .feature .ttl01 {
    margin-bottom: 24px;
  }
  #top #contents .feature .feature_list_wrap {
    max-width: calc(100% - (40/1000*100vw));
    margin: 0 auto;
  }
  #top #contents .feature .feature_list {
    width: 100%;
  }
  #top #contents .feature .feature_list:first-of-type {
    max-width: 32%;
  }
  #top #contents .feature .feature_list:first-of-type li {
    width: 100% !important;
    max-width: 100% !important;
  }
  #top #contents .feature .feature_list:last-of-type li:first-of-type {
    margin-left: 34%;
  }
  #top #contents .feature .feature_list:last-of-type li:nth-of-type(3n) {
    margin-right: 2% !important;
  }
  #top #contents .feature .feature_list:last-of-type li:nth-of-type(3n + 2) {
    margin-right: 0 !important;
  }
  #top #contents .feature .feature_list li {
    max-width: 32%;
    margin-right: 2%;
    margin-right: 2%;
    margin-bottom: 5.6vw;
  }
  #top #contents .feature .feature_list li .img_blk {
    margin-bottom: 2vw;
  }
  #top #contents .feature .feature_list li .txt_blk .feature_name {
    font-size: 1.4vw;
  }
  #top #contents .ranking_items {
    margin: 0 auto 10vw;
  }
  #top #contents .ranking_items .ttl01 {
    margin-bottom: 2vw;
  }
  #top #contents .ranking_items .ranking_items_slider {
    margin: 0 auto 5vw;
    padding: 0 4vw;
  }
  #top #contents .ranking_items .ranking_items_slider .slick-slide {
    margin: 0 2vw;
    padding-top: 0.8vw;
  }
  #top #contents .ranking_items .ranking_items_slider .slick-slide .img_blk {
    margin-bottom: 1.4vw;
  }
  #top #contents .ranking_items .ranking_items_slider .slick-slide .img_blk .ranking_icon {
    width: 4vw;
    height: 4vw;
    font-size: 2.2vw;
    top: -0.8vw;
    left: -0.8vw;
  }
  #top #contents .ranking_items .ranking_items_slider .slick-slide .txt_blk .item_name {
    font-size: 1.4vw;
  }
  #top #contents .ranking_items .ranking_items_slider .slick-slide .txt_blk .item_price {
    font-size: 1.4vw;
  }
  #top #contents .ranking_items .ranking_items_slider .slick-arrow {
    width: 1.6vw;
    height: 2.6vw;
    top: 9.2vw;
  }
  #top #contents .ranking_items .ranking_items_slider .slick-arrow.prev-arrow {
    left: 2vw;
  }
  #top #contents .ranking_items .ranking_items_slider .slick-arrow.next-arrow {
    right: 2vw;
  }
  #top #contents .checked_items {
    margin: 0 auto 10.5vw;
  }
  #top #contents .checked_items .ttl01 {
    margin-bottom: 2.6vw;
  }
  #top #contents .checked_items .checked_items_list {
    padding: 0 4vw;
  }
  #top #contents .checked_items .checked_items_list li {
    width: 15%;
    margin-right: 2%;
  }
  #top #contents .favorite_items {
    margin: 0 auto 8.8vw;
  }
  #top #contents .favorite_items .ttl01 {
    margin-bottom: 2.6vw;
  }
  #top #contents .favorite_items .favorite_items_list {
    padding: 0 4vw;
  }
  #top #contents .favorite_items .favorite_items_list li {
    width: 15%;
    margin-right: 2%;
  }
  #top #contents .favorite_items .login_blk {
    padding-top: 6vw;
  }
  #top #contents .favorite_items .login_blk p {
    font-size: 1.4vw;
    margin-bottom: 1.2vw;
  }
  #top #contents .news {
    width: calc(100% - (80/1000*100vw));
    margin: 0 auto 12.5vw;
    padding-top: 4vw;
  }
  #top #contents .news .ttl01 {
    margin-bottom: 2.8vw;
  }
  #top #contents .news .news_list {
    width: 100%;
    margin: 0 auto 6.2vw;
  }
  #top #contents .news .news_list li {
    margin-bottom: 2.5vw;
  }
  #top #contents .news .news_list li a .news_icon {
    width: 10vw;
    height: 4vw;
    font-size: 1.2vw;
    line-height: 4vw;
  }
  #top #contents .news .news_list li a .txt_blk {
    width: calc(100% - (120/1000*100vw));
  }
  #top #contents .news .news_list li a .txt_blk .news_date {
    margin-bottom: 0.8vw;
  }
  #top #contents .news .news_list li a .txt_blk .news_date .news_date_inner {
    font-size: 1.4vw;
    padding-right: 2.2vw;
  }
  #top #contents .news .news_list li a .txt_blk .news_date::after {
    top: .5em;
  }
  #top #contents .news .news_list li a .txt_blk .news_content {
    font-size: 1.4vw;
  }
  #top #contents .f_feature {
    padding: 5.6vw 0 6vw;
  }
  #top #contents .f_feature .ttl01 {
    margin-bottom: 4vw;
  }
  #top #contents .f_feature .f_feature_wrap {
    max-width: 95.4vw;
  }
  #top #contents .f_feature .f_feature_wrap .f_feature_blk {
    width: 21vw;
  }
  #top #contents .f_feature .f_feature_wrap .f_feature_blk .f_feature_img {
    margin-bottom: 2vw;
  }
  #top #contents .f_feature .f_feature_wrap .f_feature_blk h3 {
    font-size: 1.6vw;
    margin-bottom: 0.8vw;
  }
  #top #contents .f_feature .f_feature_wrap .f_feature_blk p {
    font-size: 1.4vw;
  }
  #top #footer {
    margin-top: 0;
  }
}

@media only screen and (max-width: 750px) {
  #top #contents {
    margin-top: -16vw;
  }
  #top #contents .mv_slider {
    width: 100%;
    margin-bottom: 11.46667vw;
    overflow-x: hidden;
  }
  #top #contents .mv_slider .slider_blk .slick-arrow {
    width: 10.66667vw;
    height: 10.66667vw;
    background-size: 2.26667vw 3.6vw;
    background-position: left 2.4vw center;
    top: calc(50% - (40/750*100vw));
    cursor: pointer;
  }
  #top #contents .mv_slider .slider_blk .slick-arrow.prev-arrow {
    left: -5.33333vw;
  }
  #top #contents .mv_slider .slider_blk .slick-arrow.next-arrow {
    right: -5.33333vw;
  }
  #top #contents .mv_slider .slider_blk .slick-dots {
    bottom: 4.53333vw;
    left: 3.46667vw;
  }
  #top #contents .mv_slider .slider_blk .slick-dots li {
    width: 1.86667vw;
    height: 1.86667vw;
    margin: 0 1.06667vw;
  }
  #top #contents .mv_slider .slider_blk .slider_content .txt_blk {
    bottom: 10.66667vw;
  }
  #top #contents .mv_slider .slider_blk .slider_content .txt_blk .slider_name {
    font-size: 5.86667vw;
    line-height: 1.36;
    margin-bottom: 4.26667vw;
  }
  #top #contents .mv_slider .slider_blk .slider_content .txt_blk .slider_btn {
    width: 48vw;
    height: 10vw;
  }
  #top #contents .mv_slider .slider_blk .slider_content .txt_blk .slider_btn a {
    font-size: 3.2vw;
    line-height: 10vw;
  }
  #top #contents .attention_items {
    margin: 0 auto 12.26667vw;
  }
  #top #contents .attention_items .ttl01 {
    margin-bottom: 4vw;
  }
  #top #contents .attention_items .attention_items_slider {
    margin: 0 auto 12vw;
    display: flex;
    width: 100%;
    overflow-x: scroll;
  }
  #top #contents .attention_items .attention_items_slider::after {
    content: "";
    display: block;
    width: 5.33333vw;
    height: 1px;
    flex-shrink: 0;
  }
  #top #contents .attention_items .attention_items_slider li {
    flex-shrink: 0;
    width: 34.66667vw;
    margin: 0 0 0 5.33333vw;
  }
  #top #contents .attention_items .attention_items_slider li .img_blk {
    margin-bottom: 2.4vw;
  }
  #top #contents .attention_items .attention_items_slider li .img_blk img {
    display: block;
    width: 100%;
  }
  #top #contents .attention_items .attention_items_slider li .txt_blk span {
    display: block;
  }
  #top #contents .attention_items .attention_items_slider li .txt_blk .item_name {
    font-size: 3.2vw;
    line-height: 1.33;
  }
  #top #contents .attention_items .attention_items_slider li .txt_blk .item_price {
    font-size: 3.2vw;
    line-height: 1.33;
  }
  #top #contents .campaign {
    width: 100%;
    padding: 0 5.33333vw;
    margin: 0 auto 0;
  }
  #top #contents .campaign .ttl01 {
    margin-bottom: 4.26667vw;
  }
  #top #contents .campaign .campaign_list {
    display: block;
  }
  #top #contents .campaign .campaign_list li {
    max-width: 100%;
    margin-bottom: 9.33333vw;
  }
  #top #contents .campaign .campaign_list li .img_blk {
    margin-bottom: 4vw;
  }
  #top #contents .campaign .campaign_list li .txt_blk .campaign_name {
    font-size: 3.2vw;
  }
  #top #contents .feature {
    width: 100%;
    margin: 0 auto 9.33333vw;
    background-color: #eeeeee;
    padding: 6.66667vw 0 9.06667vw;
  }
  #top #contents .feature .ttl01 {
    margin-bottom: 4vw;
  }
  #top #contents .feature .feature_list_wrap {
    width: 100%;
  }
  #top #contents .feature .feature_list {
    display: block;
  }
  #top #contents .feature .feature_list:first-of-type {
    position: static;
    max-width: 100%;
  }
  #top #contents .feature .feature_list:last-of-type li:first-of-type {
    margin-left: 5.33333vw;
  }
  #top #contents .feature .feature_list:last-of-type li:nth-of-type(3n) {
    margin-right: 0 !important;
  }
  #top #contents .feature .feature_list li {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 10.4vw;
  }
  #top #contents .feature .feature_list li .img_blk {
    margin-bottom: 5.6vw;
  }
  #top #contents .feature .feature_list li .img_blk .hover_blk {
    display: none;
  }
  #top #contents .feature .feature_list li .txt_blk .feature_name {
    font-size: 3.2vw;
  }
  #top #contents .feature .feature_list:last-of-type {
    display: flex;
    flex-wrap: initial;
    overflow-x: scroll;
    margin-bottom: 10.4vw;
  }
  #top #contents .feature .feature_list:last-of-type::after {
    content: "";
    display: block;
    width: 5.33333vw;
    height: 1px;
    flex-shrink: 0;
  }
  #top #contents .feature .feature_list:last-of-type li {
    flex-shrink: 0;
    width: 66.26667vw;
    margin-left: 5.33333vw;
    margin-bottom: 0;
  }
  #top #contents .feature .feature_list:last-of-type li .img_blk {
    margin-bottom: 3.46667vw;
  }
  #top #contents .feature .feature_list:last-of-type li .txt_blk .feature_name {
    text-align: left;
  }
  #top #contents .ranking_items {
    margin: 0 auto 12vw;
  }
  #top #contents .ranking_items .ttl01 {
    margin-bottom: 2.4vw;
  }
  #top #contents .ranking_items .ranking_items_slider {
    display: flex;
    overflow-x: scroll;
    margin: 0 auto 12vw;
  }
  #top #contents .ranking_items .ranking_items_slider::after {
    content: "";
    display: block;
    width: 5.33333vw;
    height: 1px;
    flex-shrink: 0;
  }
  #top #contents .ranking_items .ranking_items_slider li {
    width: 34.66667vw;
    flex-shrink: 0;
    margin: 0 0 0 5.33333vw;
    padding-top: 1.33333vw;
  }
  #top #contents .ranking_items .ranking_items_slider li .img_blk {
    width: 100%;
    margin-bottom: 2.66667vw;
    position: relative;
  }
  #top #contents .ranking_items .ranking_items_slider li .img_blk img {
    display: block;
    width: 100%;
  }
  #top #contents .ranking_items .ranking_items_slider li .img_blk .ranking_icon {
    width: 6.53333vw;
    height: 6.53333vw;
    background-color: #000;
    color: #fff;
    font-size: 3.46667vw;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    top: -1.33333vw;
    left: -1.33333vw;
  }
  #top #contents .ranking_items .ranking_items_slider li .img_blk .ranking_icon.ranking_1st {
    background-color: #ac8959;
  }
  #top #contents .ranking_items .ranking_items_slider li .img_blk .ranking_icon.ranking_2nd {
    background-color: #7d7d7d;
  }
  #top #contents .ranking_items .ranking_items_slider li .img_blk .ranking_icon.ranking_3rd {
    background-color: #643b14;
  }
  #top #contents .ranking_items .ranking_items_slider li .txt_blk span {
    display: block;
  }
  #top #contents .ranking_items .ranking_items_slider li .txt_blk .item_name {
    font-size: 3.2vw;
    line-height: 1.33;
  }
  #top #contents .ranking_items .ranking_items_slider li .txt_blk .item_price {
    font-size: 3.2vw;
    line-height: 1.33;
  }
  #top #contents .checked_items {
    margin: 0 auto 14.13333vw;
  }
  #top #contents .checked_items .ttl01 {
    margin-bottom: 4.53333vw;
  }
  #top #contents .checked_items .checked_items_list {
    overflow-x: scroll;
  }
  #top #contents .checked_items .checked_items_list::after {
    content: "";
    display: block;
    width: 5.33333vw;
    height: 1px;
    flex-shrink: 0;
  }
  #top #contents .checked_items .checked_items_list li {
    flex-shrink: 0;
    width: 24vw;
    margin-right: 0;
    margin-left: 3.06667vw;
  }
  #top #contents .checked_items .checked_items_list li:first-of-type {
    margin-left: 5.33333vw;
  }
  #top #contents .favorite_items {
    margin: 0 auto 16vw;
  }
  #top #contents .favorite_items .ttl01 {
    margin-bottom: 4.53333vw;
  }
  #top #contents .favorite_items .favorite_items_list {
    overflow-x: scroll;
  }
  #top #contents .favorite_items .favorite_items_list::after {
    content: "";
    display: block;
    width: 5.33333vw;
    height: 1px;
    flex-shrink: 0;
  }
  #top #contents .favorite_items .favorite_items_list li {
    flex-shrink: 0;
    width: 24vw;
    margin-right: 0;
    margin-left: 3.06667vw;
  }
  #top #contents .favorite_items .favorite_items_list li:first-of-type {
    margin-left: 5.33333vw;
  }
  #top #contents .favorite_items .login_blk {
    padding-top: 8vw;
  }
  #top #contents .favorite_items .login_blk p {
    font-size: 3.2vw;
    margin-bottom: 3.2vw;
  }
  #top #contents .news {
    width: 100%;
    margin: 0 auto 12vw;
    border-top: none;
    padding-top: 0;
  }
  #top #contents .news::before {
    content: "";
    display: block;
    width: 64vw;
    height: 1px;
    margin: 0 auto 6.66667vw;
    background-color: #000;
  }
  #top #contents .news .ttl01 {
    margin-bottom: 4vw;
  }
  #top #contents .news .news_list {
    width: 100%;
    padding: 0 10.66667vw;
    margin: 0 auto 9.86667vw;
  }
  #top #contents .news .news_list li {
    margin-bottom: 5.6vw;
    padding-bottom: 5.6vw;
    border-bottom: 1px dashed #000;
  }
  #top #contents .news .news_list li a {
    display: block;
    position: relative;
  }
  #top #contents .news .news_list li a .news_icon {
    width: 20vw;
    height: 6.66667vw;
    font-size: 2.4vw;
    line-height: 6.66667vw;
    margin-bottom: 2.4vw;
  }
  #top #contents .news .news_list li a .txt_blk {
    width: 100%;
  }
  #top #contents .news .news_list li a .txt_blk .news_date {
    display: block;
    position: absolute;
    top: 1.6vw;
    left: 22.66667vw;
    margin-bottom: 0;
  }
  #top #contents .news .news_list li a .txt_blk .news_date .news_date_inner {
    font-size: 2.93333vw;
    background-color: transparent;
    padding-right: 0;
  }
  #top #contents .news .news_list li a .txt_blk .news_date::after {
    content: none;
  }
  #top #contents .news .news_list li a .txt_blk .news_content {
    font-size: 2.93333vw;
  }
  #top #contents .f_feature {
    padding: 7.73333vw 0 8vw;
  }
  #top #contents .f_feature .ttl01 {
    margin-bottom: 4.53333vw;
  }
  #top #contents .f_feature .ttl01 span {
    display: none;
  }
  #top #contents .f_feature .f_feature_wrap {
    display: block;
    white-space: nowrap;
    font-size: 0;
    overflow-x: scroll;
  }
  #top #contents .f_feature .f_feature_wrap .f_feature_blk {
    display: inline-block;
    width: 40vw;
    margin: 0 1.73333vw;
    vertical-align: top;
  }
  #top #contents .f_feature .f_feature_wrap .f_feature_blk:first-of-type {
    margin-left: 5.33333vw;
  }
  #top #contents .f_feature .f_feature_wrap .f_feature_blk:last-of-type {
    margin-right: 5.33333vw;
  }
  #top #contents .f_feature .f_feature_wrap .f_feature_blk .f_feature_img {
    margin-bottom: 3.46667vw;
  }
  #top #contents .f_feature .f_feature_wrap .f_feature_blk h3 {
    font-size: 3.06667vw;
    margin-bottom: 1.86667vw;
    white-space: normal;
  }
  #top #contents .f_feature .f_feature_wrap .f_feature_blk p {
    font-size: 2.66667vw;
    white-space: normal;
  }
  #top #footer {
    margin-top: 0;
  }
}
